$top_header_color: #202656;
$primary: #010743;
$secondary: #03A84E;
$white: #fff;
$border_primary_color: #9AA5B5;
$box_primary_color: #F5F6F8;

$btn_primary_bg: $primary;
$btn_primary_color: #F5F6F8;
$btn_secondary_bg: $secondary ;
$btn_secondary_color: #fff;

$input_label_color: #9AA5B5;
$input_color: #202656;
$input_filed_color: #010743;

