
.graph-container {
    padding: 20px;
  }
  
  .graphtable-container {
    margin-top: 20px;
    /* height: 180px;  */
    /* overflow-y: auto;  */
    position: relative;
  }
  
  .graphdata-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  .graphTableHead {
    font-style: 'Open Sans';
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    font-weight: 700;
    text-align: left;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1; 
  }
  .graphCycle{
    font-style: 'Open Sans';
    font-size: 14px;
    line-height: 6px;
    color: '#050301';
    font-weight: 700;
    text-align: left;
  }
  .table-body-container {
    max-height: 200px; 
    overflow-y: auto;
    width: 100%;
  }
  
  .graphdata-table th, .graphdata-table td {
    padding: 10px 18px; 
    text-align: left; 
    font-style: 'Open Sans';
    font-size: 16px;
    line-height: 20px;
    color: #010743;
    word-wrap: break-word;
    text-align: left;
  }
  

  .graphHeading{
    font-style: 'Open Sans';
    font-size: 24px;
    line-height: 32px;
    color: #010743;
    font-weight: 600;
    text-align: left;
  }

  /* .graphTableHead{
    font-style: 'Open Sans';
    font-size: 18px;
    line-height: 20px;
    color: #000000;
    font-weight: 700;
    text-align: left;
    
  } */


.table-container {
    overflow-x: auto; 
  }
 
  .bordertable-container { 
    border: 1px solid #E0E0E0; 
    overflow-y: auto; 
    height: 200px; 
  } 
  
  .borderdata-table {
    width: 100%; 
    border-collapse: separate;
    border-spacing: 0;
  }
  
 .borderdata-table td {
    padding: 8px 8px; 
    text-align: left; 
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #000000;
  }
  
  .borderdata-table th {
    position: sticky; 
    top: 0;
    z-index: 1;
    background-color: #E6E9ED; 
    border-bottom: 1px solid #ddd; 
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 12px 8px; 
    text-align: left; 
    color: #000000;
    font-family: 'Open Sans';
  }
  
  .borderdata-table th:first-child, .borderdata-table td:first-child {
    border-left: 1px solid #ddd; 
  }
  
  .borderdata-table th:last-child, .borderdata-table td:last-child {
    border-right: none; 
  }
  
  .borderdata-table tbody tr {
    border-bottom: 1px solid #ddd;
  }
  
  .borderdata-table tbody tr:last-child {
    border-bottom: none; 
  }

  .pending {
    color: #F88F42;
    cursor: pointer;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .training-button {
    background-color: white;
    color: #00A10B;
    border:  1px solid #00A10B;
    padding: 5px 10px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    height: 37px;
    margin-right: 1.25rem;
  }
  
  

  