$border_primary_width: 2px;
$border_form_width: 1.5px;

$btn_font_size: 16px;
$btn_font_weight: 500;
$btn_line_height: 20px;
$btn_letter_spacing: 0em;
$btn_text_align: left;
$btn_border_radius: 30px;
$btn_padding: 16px 32px;
$btn_border_width: $border_primary_width;

$input_label_size: 16px;
$input_label_margin_bottom: 10px;
$input_label_margin_left: 10px;

$input_size: 16px;
$input_padding: 0px 15px 0px 30px;
$input_height: 60px;
$input_border_radius: 12px;