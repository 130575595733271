.patient-profile-page {
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .patient-profile {
    margin-bottom: 20px;
  }
  
  .alerts, .manage-monitoring, .device-training, .report-archives {
    margin-top: 20px;
  }
  
  .alert {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  .monitoring-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 5px;
  }
  
  /* .graph-container {
    margin-top: 20px;
  }
   */



  h1, h2 {
    color: #333;
  }
  
  /* table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  th {
    background-color: #f2f2f2;
  } */

  .outContainer {
background-color: white;
  }
  