.App {
  text-align: center;
}

.home {
  /* text-align: center; */
  /* margin-top: -50px; */
  padding-top: 70px;
  /* height: 567px; */
  width: 100%;
  background-color: #E6E9ED;
}

.content {
  padding-top: 150px;
}

.top_logo {
  /* width: 220px; */
  height: 70px;
  padding-right: 98px;
  align-items: center;
  cursor: pointer;
}

.logo_branding {
  color: #000;
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  padding-left: 18px;
  margin-bottom: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.top-margin-container {
  margin-top: 90px !important;
  min-height: 450px;
}

@media(max-width:960px) {
  .top-margin-container {
    margin-top: 50px !important;
  }
}

.top-margin-container1 {
  margin-top: 55px !important;
  min-height: 375px;
  background-color: #010743;
  margin-bottom: 20px;
  ;
}

@media (min-width: 576px) {
  .send-message-popup .modal-dialog {
    max-width: 800px !important;
  }
}


.modal1 {
  background-color: rgba(0, 0, 0, 0.5);

  &.modal-kyc {
    .modal-content {
      padding: 0px 45px 45px 45px;
      border-radius: 23px;
      width: auto;
      background-color: #F5F6F8;

      p {
        font-size: 16px;
        line-height: 20px;
        color: #9AA5B5;
        font-weight: 700;
      }

      h6 {
        text-align: left;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
      }
    }

    button {
      text-transform: capitalize;
      font-size: 14px;
      padding: 16px 32px 16px 32px;

      &.close {
        font-size: 34px;
        color: #000;
        outline: none;
        margin-right: -60px;
      }
    }
  }
}


/* .modal2 {
  &.modal-kyc{
    .modal-content{ 
        display: flex;
        width: 100%;
        justify-content: center; */
/* background-color: red; */
/* p{ font-size: 16px; line-height: 20px; color: #9AA5B5; font-weight: 700;}
        h6{ text-align: left; font-size: 24px; line-height: 32px; font-weight: 700;} */
/* }
}
} */

.modal-full-width {
  max-width: 100%;
  border-radius: 0;
  padding: 0;
  margin: 0;

  .modalTitle {
    background-color: #9AA5B5;
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);

  &.modal-kyc {
    .modal-content {
      padding: 0px 45px 45px 45px;
      border-radius: 23px;
      min-width: auto;
      background-color: #F5F6F8;

      h6 {
        text-align: left;
        font-size: 24px;
        line-height: 32px;
        font-weight: 700;
      }
    }

    button {
      padding: 16px 32px 16px 32px;

      &.close {
        font-size: 34px;
        color: #000;
        outline: none;
        margin-right: -60px;
      }
    }

    .input {
      padding: 20px 20px 20px 20px;
    }
  }
}


.header-popup {
  border-bottom: none;
  padding: 1rem 1rem 0px 10rem;
}


.custom-form-input.with-arrow::after {
  content: "\25BC";
  /* Unicode character for a down arrow */
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust as needed */
  transform: translateY(-50%);
  font-size: 16px;
  /* Adjust as needed */
  pointer-events: none;
  /* Ensures the arrow doesn't interfere with the input */
  color: #000;
  /* Adjust the color as needed */
}


.capitalized {
  text-transform: capitalize;
}

.lowerCase {
  text-transform: lowercase;
}

.ol {
  li {
    margin-top: 20px;
  }
}

.baModal {
  &.modal-kyc {
    .modal-body {
      max-height: 350px;
      overflow-y: auto;
    }
  }
}


.assignModal .modal-content {
  background-color: #F5F6F8;
  border-radius: 23px;
  padding: 20px;

  p {
    font-size: 16px;
    line-height: 20px;
    color: #9AA5B5;
    font-weight: 700;
  }

  .close {
    font-size: 34px;
    color: #010743 !important;
    outline: none;
    margin-top: -40px;
    margin-right: -30px;
    opacity: 1 !important
  }
}

.assessment-modal .close {
  display: none;
}

.footer-popup {
  border-top: none;
  padding: 1rem 1rem 0px 10rem;
}

.txt p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}