@use "./scss/color.scss" as color;
@use "./scss/mixin.scss" as layout;
@use "./scss/size.scss" as size;


.primary-btn {
    @include layout.btn_style;
    background-color: color.$btn_primary_bg;
    color: color.$btn_primary_color;
    border: size.$btn_border_width solid color.$btn_primary_color;

    &:hover {
        background-color: color.$btn_secondary_color;
        color: color.$btn_secondary_bg;
        border: size.$btn_border_width solid color.$btn_secondary_bg;
    }

    &.hover {
        background-color: color.$btn_secondary_color;
        color: color.$btn_secondary_bg;
        border: size.$btn_border_width solid color.$btn_secondary_bg;
    }

    &.hover:hover {
        background-color: color.$btn_primary_bg;
        color: color.$btn_primary_color;
        border: size.$btn_border_width solid color.$btn_primary_color;
    }

    &:focus {
        outline: none;
    }
    &:disabled {
        outline: none;
        cursor: not-allowed;
    }
}

.tertiary-btn {
    @include layout.btn_style;
    background-color: color.$btn_secondary_bg;
    color: color.$btn_secondary_color;
    border: size.$btn_border_width solid color.$secondary;

    &:hover {
        background-color: color.$btn_secondary_color;
        color: color.$btn_secondary_bg;
        border: size.$btn_border_width solid color.$btn_secondary_bg;
    }

    &.hover {
        background-color: color.$btn_secondary_color;
        color: color.$btn_secondary_bg;
        border: size.$btn_border_width solid color.$btn_secondary_bg;
    }

    &.hover:hover {
        background-color: color.$btn_primary_bg;
        color: color.$btn_secondary_color;
        border: size.$btn_border_width solid color.$btn_secondary_color;
    }

    &:focus {
        outline: none;
    }
    &:disabled {
        outline: none;
        cursor: not-allowed;
    }
}

.secondary-btn {
    @include layout.btn_style;
    background-color: color.$btn_secondary_bg;
    color: color.$btn_secondary_color;
    border: size.$btn_border_width solid color.$btn_secondary_bg;

    &:disabled {
        background-color: color.$btn_secondary_bg;
        color: color.$btn_secondary_color;
        border: size.$btn_border_width solid color.$btn_secondary_bg;
        opacity: 0.8;
    }
    &:hover {
        background-color: color.$btn_primary_bg;
        color: color.$btn_primary_color;
        border: size.$btn_border_width solid color.$btn_primary_color;
    }

    &:focus {
        outline: none;
    }
}

.form-label {
    @include layout.input_label_style;
}

.custom-form-input {
    @include layout.form_input_style;
}

.custom-form-notEditable {
    @include layout.form_input_style;
    border: none;
}

@media screen and (max-width: 1024px){
  .profile-back{
    font-size: 20px;
    line-height: 32px;
  }
  .profile-name{
    font-size: 14px;
    text-align: left;
  }
}
 