  .managestaff-table {
    width: 100%; 
    border-collapse: separate;
    border-spacing: 0; 
  }
  
 .managestaff-table td {
    padding: 12px 8px; 
    text-align: left; 
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #000000;
  }
  
  .managestaff-table th {
    background-color: #D9D9D9; 
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    padding: 12px 8px; 
    text-align: left; 
    color: #000000;
    font-family: 'Open Sans';
  }

  .managestaff-table td {
    border-bottom: 1px solid #828282 !important;
  }
  
  .managestaff-table th:first-child, .managestaff-table td:first-child {
    border-left: none; 
  }
  
  .managestaff-table th:last-child, .managestaff-table td:last-child {
    border-right: none; 
  }
  
  .managestaff-table tbody tr {
    border-bottom: 1px solid #828282 !important;
  }
  
  .managestaff-table tbody tr:last-child {
    border-bottom: 1px solid #828282;
  }