@use "./scss/color.scss" as color;
@use "./scss/mixin.scss" as layout;
@use "./scss/size.scss" as size;

* {
  margin: 0;
  font-family: 'Open Sans';
}


:root {
  --search-patient-bar: #E6E9ED;
  --white-color: #red;
  --txt-color: #06AE7A;
  --primary-color: #3889B1;
  --background-color: #F5F6F8;
  --text-color: #808191;
  --link-hover-color: rgb(56, 137, 177);
  --border-color: whitesmoke;
  --box-bg-color: #FFFFFF;
  --heading-color: #202656;
  --scrollbar-track-color: #e6e9ed;
  --scrollbar-thumb-color: #b3bbc7;
  --scrollbar-hover-color: #b3bbc7;
  --search-box-bg-color: aliceblue;
  --menu-bg-color: #fff;
  --menu-text-color: #000;
  --menu-hover-color: #000;
}

.loader {
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #03A84E;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 2s linear infinite;
  margin: auto;
  left: 0;
  right: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1440px;
  }
}

@media (max-width: 767px) {
  .container {
    padding-top: 50px;
  }
}


.container {
  position: relative;
  z-index: 1;
}

.nav_container {
  position: fixed;
  height: 75px;
  width: 100%;
  z-index: 3;
}


.top_backGround {
  height: 30px;
}

.top_navbar {
  position: absolute;
  height: 96px;
  background: #fff;
  top: 30px;
  // right: 50px;
  // left: 50px;
  padding: 20px;
  border: 1pt solid whitesmoke;
  z-index: 3;
  width: 100%;
}

.flex-container {
  display: flex;
}

.nav_logo {
  margin-top: 10px;
  width: auto;
  text-align: left;
  margin: auto;
}

.top_right_menu {
  text-align: right;
  width: 75%;
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.top_menuBar {
  display: flex;
  width: 58%;
  justify-content: space-between;
}

.c_menu_Item a {
  color: #000;
  font-family: 'Open Sans';
  cursor: pointer;
}

.c_menu_Item a:hover,
.dropdown:hover {
  color: rgb(56, 137, 177);
  font-weight: 500;
  font-family: 'Open Sans';
}

.main_content_area {
  // overflow-y: auto;
  overflow-y: hidden;
  min-height: 560px;
  margin-bottom: 50px;
  padding-top: 100px;
  // width: 82.1%;
  width: 90%;
  margin: 0 auto;
  /* position: relative; */
}

body{
  overflow: auto !important;
  padding-right: 0px !important;
  * ::-webkit-scrollbar {
    width: 12px;
    background-color: #e6e9ed;
    // border-top: 4px solid #b3bbc7;
    // border-right: 4px solid #b3bbc7;
  }
  
  * ::-webkit-scrollbar-track {
    border-radius: 12px;
  }
  
  /* Handle */
  *::-webkit-scrollbar-thumb {
    background: #B3BBC7;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background: #03a84e;
  }
}


/* side bar menu */
.backDrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  border-right: 2pt solid whitesmoke;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 99999;
  text-align: center;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  /* font-size: 25px; */
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #000;
  border-left: 10px solid rgb(32, 38, 86);
  border-right: 5px solid rgb(32, 38, 86);
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.sidenav a,
.dropdown-btn {
  padding: 20px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  outline: none;
  border-bottom: 1px solid;
}

.sidenav a:hover,
.dropdown-btn:hover {
  font-weight: 500;
}

.main {
  margin-left: 200px;
  /* Same as the width of the sidenav */
  font-size: 20px;
  /* Increased text to enable scrolling */
  padding: 0px 10px;
}

.sidenav .active {
  color: black;
  border-left: 10px solid #00A10B;
  border-right: 5px solid #00A10B;
}

.web_menu a:hover {
  text-decoration: none;
}

.web_menu .active {
  color: #00A10B;
  font-family: 'Open Sans';
  font-weight: 500;
}

.dropdown-container {
  display: none;
  padding-left: 25px !important;
}

.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.dropdown {
  /* position: relative; */
  display: inline-block;
  font-family: 'Open Sans';
}

.dropdown-content {
  min-width: 200px;
  display: none;
  position: absolute;
  right: -50px;
  color: #3889B1;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  white-space: nowrap;
  text-align: left;
  background-color: #F5F6F8;
  text-decoration: none;
  z-index: 2;
}

.dropdown:hover .dropdown-content {
  display: block;
  color: #000;
  font-weight: 400;
  font-family: 'Open Sans';
}

.dropdown-content a {
  font-size: 14px;
  color: #3889B1;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid#000;
  background: #F5F6F8;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.headerUserDetails {
  display: flex;
}

/* Profile Info */
.profile-info {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: center;
  // cursor: none;
  font-family: 'Open Sans';
}

.profile-name {
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 20px;
  // font-weight: 500;
  color: #808191;
  cursor: default;
}

.profile-back {
  font-size: 24px;
  line-height: 32px;
  cursor: default;
  // cursor: none;
  // font-weight: 700;
}

.settingIcon {
  display: flex;
  align-items: center;
  padding: 10px;
}


@media(min-width:960px) {
  .mobile_menu {
    display: none !important;
  }
}

@media(max-width:960px) {
  .web_menu {
    display: none !important;
  }

  // .top_navbar {
  //   left: 20px !important;
  //   right: 20px !important;
  // }
  .top_right_menu {
    justify-content: flex-end !important;
  }

  .vertical-line {
    border-left: none;
    margin-top: 30px;
    border-top: 1px dashed #03A84E;
  }
}



/* side bar menu end */


.provider-login {
  // max-height: 355px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.Login {
  margin-top: inherit;
}

.login {
  justify-content: center;
  width: 259px;
  border: 1px solid
}

/* .login_providerRegister {
  margin-top: 15%;
} */

.center_div {
  height: 450px;
  align-items: center;
}

.vertical-line {
  min-height: 255px;
  border-left: 1px dashed #03A84E;
}

.custom-form-input {
  width: 100%;
  height: 60px;
  border: 1.5px solid #9AA5B5;
  border-radius: 12px;
  padding: 0px 15px 0px 30px;
  font-size: 16px;
  font-family: 'Open Sans';
}

.custom-form-notEditable {
  width: 100%;
  height: 60px;
  border: none;
  /* border-radius: 12px; */
  padding: 0px 15px 0px 30px;
  font-size: 16px;
  font-family: 'Open Sans';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.custom-form-notEditable-no-border {
  width: 100%;
  height: 60px;
  border: none;
  /* border-radius: 12px; */
  padding: 0px 15px 0px 30px;
  font-size: 16px;
  font-family: 'Open Sans';
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.custom-form-notEditable-no-border:focus,
.custom-form-notEditable-no-border:hover,
.custom-form-notEditable-no-border:active {
  border: none; /* Explicitly set border to none for these states */
  outline: none; /* Remove default focus outline if needed */
  cursor: default;
}
.search {
  padding: 0px 15px 0px 50px;
}

.form-label {
  color: #9AA5B5;
  font-family: 'Open Sans';
  font-size: 16px;
  margin-bottom: 10px;
  margin-left: 10px;
}


.form-check-input {
  height: 24px;
  width: 24px;
  /* padding: 2px; */
}

.form-check-input:hover {
  border-radius: 92px;
}

.horizontal-line {
  margin-top: 10px;
  border-top: 1px dashed #000;
}

.otp-box {
  width: 54px;
  height: 60px;
  border: 1.5px solid #B3BBC7;
  border-radius: 12px;
  margin: 10px;
  font-size: 32px;
  text-align: center;
  outline: none;
}

.otp-box:focus {
  border-color: rgb(56, 137, 177);
}

.Switch {
  font: 'Poppins';
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
}


.otp-passcode {
  font-size: 24px;
  color: #808191;
}

/* table patient alert */

.box {
  padding: 20px;
  height: 182px;
  width: auto;
  background-color: #F5F6F8;
  border-left: 5px solid #00A10B;
  overflow-y: scroll;
}

.box-header {
  display: flex;
  flex-direction: row;
  // position: sticky;
  // top: 0;
}

.inner-box {
  padding: 10px;
  height: 90px;
  width: 100%;
  /* overflow-y: scroll;
  overflow-x: scroll; */
}

.inner-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 50px;
  text-align: left;
}


// .box::-webkit-scrollbar {
//   width: 10px;
//   background-color: #e6e9ed;
// }


// .box::-webkit-scrollbar-track {
//   max-height: 182px; 
// }

// .box::-webkit-scrollbar-thumb {
//   background: #b3bbc7;
//   height: 49px;
//   width: 8px;
//   border-radius: 25px;
// }

// .box::-webkit-scrollbar-thumb:hover {
//   background: #b3bbc7;
// }


@media (max-width: 768px) {
  .inner-content {
    flex-direction: row;
    align-items: left;
  }

  .box {
    border-left: none;
  }
}

@media (max-width: 800px) {
  .vertical-line {
    display: none;
  }
}

/* -----------patients table------------- */


.table-responsive {
  max-height: 500px;
  /* border-bottom: 2px solid #CCD2DA; */
  overflow-y: auto;
  overflow-x: auto;
}


.custom-adli-table thead tr:nth-child(1) th {
  background: #CCD2DA;
  position: sticky;
  top: 0;
  z-index: 1;
}



// .custom-adli-table tbody tr:not(:last-child){ 
//   border-top: 10px solid white; 
// }  
.custom-adli-table tbody tr {
  border-top: 10px solid white;
}

.custom-adli-table td {
  background-color: #f5f6f8;
  font-size: 18px;
  /* padding: 16px 32px; */
  white-space: nowrap;
}

.innercontent-fixed-font-size * {
  font-size: 18px !important;
}

.custom-adli-table th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ccd2da;
  font-weight: 700;
  font-size: 18px;
}

.h {
  border-left: 5px solid #00A10B;
}

.custom-adli-table .h {
  border-left: 5px solid #00A10B;
}

.table-noborder tr {
  border: none
}

.alert-table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  text-align: left;
  white-space: nowrap;

  >table,
  td {
    border-top: none;
    padding: 2px 5px
  }
}

.completed_assessment_container .completed_assessment_div {
  min-height: 55px;
  align-items: center;
}

.lastReading {
  min-height: 20px;
  align-items: center;
  color: #010743;
}

.patienttableHead{
  font-weight: 700;
  font-size: 18px;
}

/* search box */

.icon-container {
  position: relative;
  // margin-right: 10px;
}

.icon-container i {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  z-index: 1;
}


/* dashboard missed assessment box */

.dasboardBox {
  padding: 10px;
  height: 82px;
  max-width: 458px;
  border-radius: 12px;
  background-color: #FFFFFF;
}

/* dasboard manage assessment table */
.dashboard-ka-table {
  max-height: 251px;
  margin-top: -40px;
  padding: 5px;
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
}

.dashboard-ka-table-head {
  width: auto;
  height: 51px;
  background-color: #CCD2DA;
}

.dashboard-ka-table-body {
  background-color: #FFFFFF;
  height: 71px;
}

.table-footer {
  padding: 16px 5px;
  display: flex;
  width: 100%;
  text-align: center;
  background-color: #CCD2DA;
}

/* ADCS box */
.adcsBox {
  padding: 20px;
  // max-height: 447px;
  // max-width: 576px;
  border-radius: 15px;
  margin-bottom: 50px;
}

.chart-box {
  margin: 15px;
  // max-height: 160px;
  // max-width: 446px;
}

.adcs-completed {
  margin: 0px 20px 0px 0px;
  max-height: 115px;
  padding: 5px 20px 10px 10px;
  width: auto;
  overflow-y: scroll;
  min-height: 120px;
}

// .adcs-completed::-webkit-scrollbar {
//   width: 10px;
//   border-radius: 25px;
//   background-color: #e6e9ed;
// }


// .adcs-completed::-webkit-scrollbar-track {
//   max-height: 115px;
//   /* box-shadow: inset 0 0 5px grey; */
// }

// .adcs-completed::-webkit-scrollbar-thumb {
//   background: #b3bbc7;
//   height: 12px;
//   width: 10px;
//   border-radius: 10px;
// }

// .adcs-completed::-webkit-scrollbar-thumb:hover {
//   background: #b3bbc7;
// }

.chart-adcs-completed {
  margin: 20px;
}

/* assessment table */

.assess-table {
  margin-top: 50px;
  padding: 5px;
  width: 100%;
  border-spacing: 0 10px;
  overflow-y: scroll;
  border-collapse: separate;
}

.assess-table th {
  text-align: left;
  padding: 16px 32px;
  column-gap: 30px;
  background-color: #3889B1;
  color: #fff;
}

.assess-table td {
  text-align: left;
  background-color: #D7E7EF;
  margin-top: 10px;
  padding: 16px 32px;
}

/* recent css */

.h3 {
  font-size: 48px;
  line-height: 72px;
  font-family: 'Open Sans';
}

.h5 {
  font-size: 32px !important;
  line-height: 48px !important;
  font-family: 'Open Sans';
}

.h6 {
  font-size: 24px;
  line-height: 32px;
  font-family: 'Open Sans';
  font-weight: 500;
}

.p {
  font-size: 12px;
  line-height: 16px;
  font-family: 'Open Sans';
}

.regular-p {
  font: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-family: 'Open Sans';
}

.graphText {
  font-size: 14px;
  font-family: 'Open Sans';
  margin-bottom: 2px;  
}

.graphText1 {
  font-size: 14px;
  font-family: 'Open Sans';
  margin-bottom: 2px;  
  word-break: break-all;
}

@media (max-width: 768px) { 
  .assessques1 {
    margin-bottom: 0; 
    margin-right: 5px; 
  }

  .graphText1 {
    margin-bottom: 0;
    word-break: break-word;
  }
}

.loginHeading {
  font-size: 48px;
  // line-height: 35px;
  font-family: 'Open Sans';
  font-weight: 600;
  align-items: 'center';
  color: #03a84e;
}

.loginSubHeading {
  font-size: 28px;
  line-height: 35px;
  font-family: 'Open Sans';
  font-weight: 400px;
  align-items: 'center';
  color: #010743;
  margin-bottom: 60px;
}

.registerList {
  font-size: 14px;
  line-height: 22px;
  font-family: 'Open Sans';
  font-weight: 400px;
  align-items: 'center';

}

.assessques {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';
}

.assessques1 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';
  white-space: normal;
}
.patientInfo {
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .mobile-number {
    display: block; 
    white-space: normal;
  }
  .assessques1 {
    white-space: nowrap;
    word-break: break-all;
  }
}

.big-p {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Open Sans';
}

.thick-heading {
  font-weight: 700;
  font-family: 'Open Sans';
}

.thick-heading1 {
  font-weight: 700;
  color: #03a84e;
}


.profile_page_container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.patient-container {
  padding-right: 30px;
  padding-left: 70px;
  padding-bottom: 10px
}

.patientsearch {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Open Sans';
  color: white;
  text-align: left;
  margin-top: 20px;
}

.thick-subheading {
  // font-weight: 700;
  font-family: 'Open Sans';
  line-height: 2;
}

.patient-profile-div {
  flex: 0 0 20%;
  max-width: 20%;
}

.search-patient-div {
  flex: 0 0 50%;
  max-width: 50%;
}

@media print {
  .print-hide {
    display: none !important;
  }
}

// .thick-title {
//   float: right;
// }

.measurementName {
  font-weight: 400;
  color: #010743;
}


.heading {
  font-weight: 600;
  color: #010743;
}

.headingPatientProfile {
  font-weight: 600;
  color: white;
}

.thin-heading {
  font-weight: 400;

}

.textColor {
  color: var(--txt-color);
}

.headlineColor {
  color: #808191;
}

.dashColor {
  color: #000;
}

.graphColor {
  color: #3889B1;
}

.form-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #9AA5B5;
  font-family: 'Open Sans';
}

ƒ
/* react navbar */

.upper-nav {
  top: 20px;
  position: relative;
  height: 90px;
  width: 90%;
  margin: auto;
  z-index: 3;
  background-color: aliceblue;
  display: flex;
  align-items: center;
}

.icon {
  margin-top: 35px;
}

.bottom-nav {
  position: absolute;
  width: 100%;
  height: 75px;
  background-color: #202655;
}


.setting-content {
  border-collapse: separate;
  display: none;
  width: 260px;
  position: absolute;
  top: 100%;
  right: 0;
  color: #3889B1;
  padding: 1px;
  background-color: #F5F6F8;
  font-family: 'Open Sans';
  align-items: flex-start;
}

.setting-content {
  a {
    color: #3889B1;
    font-family: 'Open Sans';
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 5px;
    text-align: left;
  }

  a:hover {
    font-family: 'Open Sans';
    transition: none;
    transform: none;
  }

  hr {
    margin: 0px;
  }
}

.dropdown:hover .setting-content {
  display: block;
}


.arrow-down {
  margin-top: -10px;
}

.searchBox {
  position: relative;
}

.searchBox-list {
  position: absolute;
  top: 220px;
  right: 300px;
  bottom: 200px;
}

/*  */


.add-cg-btn {
  width: auto;
  background-color: var(--btn-color);
  padding: 10px 20px;
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid;
  font-family: 'Open Sans';
  color: var(--background-color);
}

.cross {
  width: 50px;
  height: 50px;
  /* padding: 1px 25px; */
  border-radius: 100%;
  border: none;
  font-family: 'Open Sans';
}

.custom-form-input-date {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-family: 'Open Sans';
  text-align: left;
  background-color: white;
}
.custom-form-input-date2 {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-family: 'Open Sans';
  text-align: left;
  background-color: white;
  .react-date-picker__wrapper {
    border: 1.5px solid #9AA5B5;
    border-radius: 12px;
    font-family: 'Open Sans';
    height: 60px;
    font-size: 16px;
    padding: 0px 15px 0px 30px;
    button {
      padding: 0;
      // margin: -20px;
    }
  }
}
.custom-form-input-date1 {
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-family: 'Open Sans';
  background-color: #FFFFFF;

  // padding-left: 0px;

  .react-date-picker__wrapper {
    border: none;
    border-radius: 12px;
    font-family: 'Open Sans';
    // padding-left: 0px;
    padding: 0px 0px 0px 0px;
    margin-left: -20px;

    button {
      padding: 0;
      // margin: -20px;
    }
  }
}

.custom-form-input-search-patient {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-family: 'Open Sans';
  background-color: var(--search-patient-bar);
}

.react-date-picker__wrapper {
  height: 50px;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-family: 'Open Sans';
  padding: 0px 15px 0px 30px;
}

.errorText {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

// new changes

.hideOnMobile {
  position: relative;
  display: flex;
}

.visibility-hidden {
  visibility: hidden;
}

.absolute {
  position: absolute;
}

.submenu-wrap {
  font-family: 'Open Sans';

  .MuiMenu-paper {
    background: color.$box_primary_color;
    box-shadow: 0px 4px 4px 0px #0000001A;

  }

  .MuiMenuItem-root {
    // padding: 13px 14px;
    border-bottom: 1px solid #A4AEBC;
  }

  .MuiMenuItem-root:last-child {
    border-bottom: none;
  }

  .MuiMenu-list {
    padding: 0;
  }

  .MuiMenuItem-root a {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: none;
    width: 100%;
    padding: 5px 0px;
    color: #03A84E;
  }
}

#patient-sub-ment .MuiMenu-paper {
  width: 11.7%;
}

#support-sub-ment .MuiMenu-paper {
  width: 17.3%;
}

#setting-sub-ment .MuiMenu-paper {
  width: 20.9%;
  top: 125px !important;
  left: calc(100% - 26%) !important;
}

@media (min-width: 576px) {
  .assign-assessment-pop-model .modal-dialog {
    max-width: 570px;
  }
}


.select-input-wrap {
  .MuiInputBase-root {
    width: 100%;
    border-radius: 12px;
    border: size.$btn_border_width solid color.$btn_secondary_color;
    background: #fff;
    height: 60px;

    &:hover {
      border: size.$btn_border_width solid color.$btn_secondary_color;
    }

    input {
      border: none !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: none !important;
    }

    .MuiSelect-select {
      font-family: 'Open Sans';
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      padding: 16px 32px;
      color: color.$input_color;
    }
  }
}

.MuiMenuItem-root.select-input-option {
  background: color.$box_primary_color;
  font-family: 'Open Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: color.$secondary;
  padding: 14px;
  border-bottom: 1px solid #A4AEBC;
}

.form-control:focus,
.custom-form-input:focus {
  color: color.$input_color;
  background-color: none;
  // border: size.$btn_border_width solid color.$btn_secondary_color;
  outline: 0;
  box-shadow: none;
}

button:focus {
  outline: none !important;
}

.MuiMenu-list {
  padding: 0 !important;
}

.search-lists-wrap {
  list-style: none;
  border: none;
  background: color.$box_primary_color;
  position: absolute;
  // bottom: -68px;
  width: 100%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1019607843);
  border-radius: 4px;
  padding: 0;
  margin: 0;
  z-index: 2;

  li {
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: color.$secondary;
    ;
    padding: 14px;
    border-bottom: 1px solid #A4AEBC;
    cursor: pointer;
  }

  li:last-child {
    border: none;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.patient-profile-bg {
  width: 100%;
  padding: 0 3.75% 3.13% 7.8%;
}

.searchBox {
  .icon-container i {
    left: 15px;
    top: 34%;
  }

  .search-lists-wrap {
    bottom: -43px;
  }
}

.send-message-wrap {
  position: relative;

  .btn {
    position: absolute;
    top: 12px;
    transform: rotate(45deg);
    background: transparent;
    border: none;
    color: color.$primary;
    right: 0;
  }

  input {
    padding: 0 30px 0 15px !important;
  }

  .btn:focus {
    outline: none;
  }
}

.recent-complete-table-wrap tr {
  display: grid;
  grid-template-columns: 25% 25% 50%;
}

.recent-complete-table-wrap table,
.recent-complete-table-wrap thead,
.recent-complete-table-wrap tbody {
  display: grid;
  grid-template-columns: 100%;
}

.recent-complete-table-wrap table,
.recent-complete-table-wrap thead,
.recent-complete-table-wrap tr {
  overflow: hidden !important;
  margin: 0 !important;
}

.recent-complete-table-wrap tbody.tbody-scroll {
  display: block;
  overflow-y: scroll;
  height: 420px;
}

.nav-bar {
  width: 100%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  margin: 0;
  cursor: pointer;
}


li {
  a {
    color: black;
  }

  span {
    color: black;
    font-family: 'Open Sans';
  }

  a:hover {
    color: #03A84E;
    // transform: scale(1.15);
    font-weight: bold;
  }

  span:hover {
    color: #03A84E;
    // transform: scale(1.15);
    font-weight: bold;
  }
}

.custom-form-input-text-area {
  width: 100%;
  height: 60px;
  border: 1.5px solid #9AA5B5;
  border-radius: 12px;
  padding: 20px;
  font-size: 16px;
  font-family: 'Open Sans';
}

.custom-form-input-mobile {
  position: relative !important;
  height: 60px !important;
  border: 1.5px solid #9AA5B5;
  border-radius: 12px !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 20px;
  font-size: 16px;
  font-family: 'Open Sans';
}

#countryCode {
  padding: 0;
  background: transparent;
  border: 0;
}

.ck,
ck-icon {
  z-index: 0;
}

.react-pdf__Page__annotations.annotationLayer {
  height: 0px !important;
  width: 0px !important;
}

.react-pdf__Page__textContent.textLayer {
  display: none !important;
}

.input-f {
  width: 100%;
  // min-width: 320px;
}

.input-box {
  width: 100%;
  // max-width: 450px;
  // margin-bottom: 10px;
  height: 50px;
  border-radius: 12px;
  outline: none;
  border: 1.5px solid #9AA5B5;
  padding: 0px 10px 0px 10px;
  ;
  font-family: "Open Sans";
  font-size: 16px;
}

.login-box {
  width: 80%;
}

.login-btn-container {
  display: flex;
  justify-content: space-between;
}


.main_login_btn {
  background-color: #03A84E !important;
  font-weight: 500;

  &:hover {
    background-color: #fff !important;
    color: #08833d !important;
    border: 2px solid #08833d !important;
  }
}

.invalid-input {
  color: red !important;
}

.input-is-invalid {
  border: 1px solid red;
}

.forget-modal-container {
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
  display: none;
  justify-content: center;
  align-items: center;
}

.hide-forget-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.forget-modal {
  background-color: white;
  border-radius: 30px;
  max-width: 400px;
  max-height: 392px;
  margin-top: 90px;
}

.close-button {
  float: right;
  border: none;
  background-color: #ffffff;
  margin: 15px;
  display: block;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.rpm-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.login-btn {
  position: relative;

  .tail-spin-loading {
    top: 15px;
    right: 10px;
    position: absolute;
  }
}

.error-adMonitor {
  border: 1.5px solid red !important;
  border-radius: 12px;
}

.custom-hr {
  border: none;
  border-bottom: 1px solid #000;
  margin: 20px 0;
  width: 100%;
}


.button-primary {
  background-color: var(--primary-color);
  font-weight: 400;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  width: 100%;
  max-width: 480px;
  // min-width: 280px;
  color: white;
  margin: 20px 0;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-disable {
  background-color: var(--text-color);
  font-weight: 400;
  font-size: 16px;
  padding: 15px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  min-width: 250px;
  max-width: 480px;
  // min-width: 280px;
  color: white;
  margin: 20px 0;
  height: 50px;
  cursor: not-allowed;
}

.button-secondary {
  background-color: white;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 30px;
  outline: none;
  border: 0;
  color: var(--txt-color);
  margin: 10px 0;
  border: 1px solid var(--txt-color);
  height: auto;
  width: 50%;
  cursor: pointer;
}

.loader-wrap {
  position: fixed;
  z-index: 11;
  top: 0;
  width: 100%;
  left: 0;

  .MuiLinearProgress-bar {
    background-color: #02a52f !important;
  }
}

.ptb-10 {
  padding: 10px 0;
}

.pb-10 {
  padding-bottom: 10px;
}

.search-input-wrap {
  input {
    color: #02a52f !important;

    &::placeholder {
      color: #02a52f !important;
    }
  }
}

.primary-text-color {
  color: #02a52f !important;
}

.Toastify__toast-container--top-right {
  z-index: 1111;
}

.signInIDmeImage {
  height: 50px;
  max-width: 250px;
  cursor: pointer;
  border-radius: 25px;
}

@media(max-width:960px) {
  .signInIDmeImage {
    max-width: 200px;
  }

  .loginHeading {
    font-size: 45px;
  }
}

.card-img {
  height: 225px;
}

.cur-pointer {
  cursor: pointer !important;
}

.pop-up-heading {
  font-size: 28px !important;
  color: #010743 !important;
  font-weight: 600 !important
}

.font-14 {
  font-size: 14px !important;
}

.input-border {
  border: 1.5px solid #9AA5B5 !important;
  border-radius: 12px !important;
}

.text-danger {
  font-size: 12px !important;
}

.css-1dimb5e-singleValue {
  color: #010743 !important;
}

.radio-label {
  margin-left: 8px;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.radio-black {
  color: #010743 !important;
  padding-left: 10px;
  padding-right: 10px;
}

.dark-color {
  color: #010743 !important;
}

.input-end-label {
  margin-left: 5px;
  color: #9AA5B5;
}

.form-control {
  color: #010743 !important;
}

.css-k8bo74-control {
  padding: 0px !important;
}

.css-ellhb1-control {
  padding: 0px !important;
}
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px; /* Adjust based on your layout needs */
}
.custom-tooltip {
  font-size: 12px !important;
  padding: 5px !important;  
}
.custom-height .input-f .input-box{
  height: 60px !important;
}