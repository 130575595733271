/* Ensure you include this CSS in your build process or import it directly in your component */


#assessment-content h5,
#assessment-content a {
  font-family: 'Aptos', sans-serif;
}

#assessment-content p {
  font-family: 'Aptos', sans-serif;
  font-size: 18px;
  font-style: normal;
  color: #000000;

}

#assessment-content p span {
  font-family: 'Aptos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #212121;
}

#assessment-content span {
  font-family: 'Aptos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  color: #212121;
}

#assessment-content .custom-adli-table {
  font-family: 'Aptos', sans-serif;
}

#assessment-content table {
  font-size: 14px;
}

#assessment-content .font-bold {
  font-weight: 700;
  font-family: 'Aptos', sans-serif;
  color: #212121;
}

#assessment-content th,
#assessment-content td {
  padding: 8px;
  text-align: left;
}

#assessment-content tr {
  margin-top: 4;
}

#assessment-content th {
  font-weight: bold;
}

#assessment-content hr {
  border-top: 1px solid #212121;
}

#assessment-content .color-gray {
  font-family: 'Aptos', sans-serif;
  color: #666;
}

#assessment-content .rotated-content {
  display: inline-block;
  padding: 20px;
  background-color: #3498db;
  color: white;
  transform: rotate(90deg);
  transform-origin: left top;
  /* Adjust this if needed */
  height: 200px;
  /* Adjust as needed */
  width: 200px;
  /* Adjust as needed */
}

#assessment-content .rotate-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}

#assessment-content .italic {
  font-style: italic;
}